//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ViuHeader from 'viu/components/viu-header/viu-header.vue';
import ViuLabel from 'viu/components/viu-label/viu-label.vue';
import ViuSelect from 'viu/components/viu-select/viu-select.vue';
import ViuInput from 'viu/components/viu-input/viu-input.vue';
import ViuButton from 'viu/components/viu-button/viu-button.vue';
import validateForm from '$lib/validate-form.js';

import './style/firmantes-style.scss';

export default {
  name: 'Firmantes',
  components: {
    ViuHeader,
    ViuLabel,
    ViuSelect,
    ViuInput,
    ViuButton
  },
  props: {
    staticData: Object,
    value: Array,
    owner: Object
  },
  watch: {
    value: {
      handler() {
        this.$emit('input', this.value);
      }
    }
  },
  methods: {
    addClick() {
      this.$emit('add');
    },
    deleteFirmante(index) {
      if (this.value.length === 1) return;
      this.$emit('deleteFirmante', index);
    },
    validate() {
      const result = this.value.map((element, ind) => {
        return validateForm.validate(this, [], ind);
      });
      return result.reduce((acc, datos) => acc + datos, 0);
    }
  }
};
