//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ViuHeader from 'viu/components/viu-header/viu-header.vue';
import ViuBox from 'viu/components/viu-box/viu-box.vue';
import ViuButton from 'viu/components/viu-button/viu-button.vue';
import ViuCheckbox from 'viu/components/viu-checkbox/viu-checkbox.vue';
import StepLayout from '../step-layout.vue';
import DatosPuntoSuministro from './componentes/datos-punto-suministro.vue';
import DetalleOferta from '../detalle-oferta.vue';
import CumplimentacionRapida from './componentes/cumplimentacion-rapida.vue';
import TitularSuministro from './componentes/titulares-suministro.vue';
import Firmantes from './componentes/firmantes.vue';
import Contactos from './componentes/contactos.vue';
import DireccionTitular from './componentes/direcciones-titular.vue';
import DireccionSuministro from './componentes/direccion-suministro.vue';
import EnvioFactura from './componentes/envio-factura.vue';
import Facturacion from './componentes/facturacion.vue';
import UploadFiles from './componentes/upload-files.vue';
import validateForm from '$lib/validate-form.js';

import * as api from '../../lib/api.js';

export default {
  name: 'StepFormV3',
  components: {
    ViuBox,
    ViuHeader,
    ViuCheckbox,
    ViuButton,
    StepLayout,
    DatosPuntoSuministro,
    DetalleOferta,
    CumplimentacionRapida,
    TitularSuministro,
    Firmantes,
    Contactos,
    DireccionTitular,
    DireccionSuministro,
    EnvioFactura,
    Facturacion,
    UploadFiles
  },
  props: {
    houseType: String,
    cups: Object,
    formExternalData: Object,
    contractData: Object,
    contractCreated: Object,
    leadId: String,
    offers: Object,
    allSteps: Object
  },
  data() {
    return {
      contratoProcesed: false,
      staticData: {
        optionsTipoIdentificador: [],
        optionsTipoPersona: [
          { text: 'J-Juridica', value: 2 },
          { text: 'F-Fisica', value: 1 }
        ],
        optionsCNAE: [],
        optionsTipoContacto: [],
        optionsTipoVia: [],
        optionsEnvioFactura: [
          { text: 'Factura papel', value: 'factura_papel' },
          { text: 'Factura electrónica', value: 'factura_electronica' }
        ],
        optionsEnviarFactura: [
          { text: 'Email del titular del suministro', value: 'Email del titular del suministro' },
          { text: 'Otra dirección email (Indicar)', value: 'Otra dirección email (Indicar)' }
        ],
        optionsDireccionEnvioPapel: [
          {
            text: 'Dirección del titular del suministro',
            value: 'Dirección del titular del suministro'
          },
          { text: 'Otra dirección (Indicar)', value: 'Otra dirección (Indicar)' }
        ],
        optionsTitularCuenta: [
          { text: 'El titular del suministro', value: 'El titular del suministro' },
          { text: 'Otra persona', value: 'Otra persona' }
        ],
        optionsFile: [
          { text: 'Fichero a firma', value: 'Fichero a firma' },
          { text: 'Fichero adicional', value: 'Fichero adicional' }
        ],
        optionsProvincia: []
      },
      arraysArchivos: {
        documentosDNI: [],
        documentacionEmpresa: [],
        documentacionBateriaVirtual: [],
        documentacionAdicional: []
      },
      checkScoring: false,
      recibirComunicados: false,
      terminosCondiciones: false,
      loaded: false,
      datosPuntosSuministro: {
        comercializadora_saliente: '',
        tarifa: '',
        p1: '',
        p2: '',
        p3: '',
        p4: '',
        p5: '',
        p6: ''
      },
      dataToSend: {
        // Titular suministro
        owner: {
          personType: '',
          document: '',
          documentType: '',
          phone: '',
          socialReason: '',
          email: '',
          confirmEmail: '',
          cnae: '',
          att_factura: '',
          name: '',
          lastName: '',
          secondLastName: ''
        },
        firmantes: [
          {
            personType: 1,
            document: '',
            name: '',
            lastName: '',
            secondLastName: '',
            phone: '',
            email: '',
            confirmarEmail: '',
            razonSocial: ''
          }
        ],
        contacto: [],
        direccion_titular: {
          postalCode: '',
          locality: '',
          province: '',
          ref_catastral: '',
          viaType: '',
          line1: '',
          number: '',
          floor: '',
          aclarador: '',
          streetportal: '',
          stairs: '',
          door: ''
        },
        direccion_suministro: {
          check: false,
          codigo_postal: '',
          localidad: '',
          province: '',
          ref_catastral: '',
          tipo_via: '',
          direccion: '',
          number: '',
          floor: '',
          aclarador: '',
          streetportal: '',
          stairs: '',
          door: ''
        },
        envio_factura: {
          forma_envio_factura: 'factura_papel',
          factura_electronica: 'Email del titular del suministro',
          email: '',
          options_direccion: 'Dirección del titular del suministro',
          tipo_identificador: '',
          identificador: '',
          tipo_persona: '',
          razon_social: '',
          nombre: '',
          primer_apellido: '',
          segundo_apellido: '',
          codigo_postal: '',
          localidad: '',
          provincia: '',
          tipo_via: '',
          direccion: '',
          number: '',
          floor: '',
          streetportal: '',
          stairs: '',
          door: '',
          factura_electronica_identificador: '',
          factura_electronica_tipo_identificador: '',
          factura_electronica_nombre: '',
          factura_electronica_primer_apellido: '',
          factura_electronica_segundo_apellido: '',
          factura_electronica_tipo_persona: '',
          factura_electronica_razon_social: ''
        },
        facturacion: {
          iban: '',
          swift: '',
          titularSelect: 'El titular del suministro',
          documentType: '',
          document: '',
          name: ''
        }
      }
    };
  },
  created() {
    this.getStaticData();
    if (this.allSteps['2'].inputData.type === 'pdf') this.setFactura(this.allSteps['2']);
    if (this.allSteps['2'].inputData.type !== 'pdf') this.setData(this.allSteps['2']);

    this.setCNAE();
  },
  methods: {
    setCNAE() {
      // Ponemos el CNAE del primer formulario
      if (this.allSteps['4'].inputData.configuracionBasica.codigo_cnae !== '') {
        this.dataToSend.owner.cnae = this.allSteps['4'].inputData.configuracionBasica.codigo_cnae;
        return;
      }

      // Primera residencia
      if (this.allSteps['1'].inputData.house_type === 'first_residence') {
        this.dataToSend.owner.cnae = 9820;
        return;
      }

      // Segundo residencia
      if (this.allSteps['1'].inputData.house_type === 'second_residence') {
        this.dataToSend.owner.cnae = 9821;
      }
    },
    validate() {
      let result = 0;

      const childTitularesSuministro = validateForm.checkChildren('TitularesSuministro', this);
      if (childTitularesSuministro !== null) {
        const res = childTitularesSuministro.validate();
        result += res;
      }

      const childFirmantes = validateForm.checkChildren('Firmantes', this);
      if (childFirmantes !== null) {
        const res = childFirmantes.validate();
        result += res;
      }

      const childContacto = validateForm.checkChildren('Contacto', this);
      if (childContacto !== null) {
        const res = childContacto.validate();
        result += res;
      }

      const childDireccionesTitular = validateForm.checkChildren('DireccionesTitular', this);
      if (childDireccionesTitular !== null) {
        const res = childDireccionesTitular.validate();
        result += res;
      }

      const childDireccionSuministro = validateForm.checkChildren('DireccionSuministro', this);
      if (childDireccionSuministro !== null) {
        const res = childDireccionSuministro.validate();
        result += res;
      }

      const childEnvioFactura = validateForm.checkChildren('EnvioFactura', this);
      if (childEnvioFactura !== null) {
        const res = childEnvioFactura.validate();
        result += res;
      }

      const childFacturacion = validateForm.checkChildren('Facturacion', this);
      if (childFacturacion !== null) {
        const res = childFacturacion.validate();
        result += res;
      }

      if (!this.terminosCondiciones) {
        result += 1;
        this.$refs.terminosCondiciones.invalidStorage = true;
      }

      if (result > 0) {
        // SCROLL PARA VISUALIZAR EL PRIMER ERROR
        if (result !== 0 || this.invalidCups) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.$el.getElementsByClassName('invalid')[0].scrollIntoView();
            }, 500);
          });
          this.sending = false;
        }

        return false;
      }
      return true;
    },
    onContinue() {
      if (!this.validate()) return;
      const formData = new FormData();

      // Titular del suministro
      const titularSuministro = this.dataToSend.owner;
      titularSuministro.address = this.dataToSend.direccion_titular;
      formData.append('titularContrato', JSON.stringify(titularSuministro));

      // Firmantes y Contacto
      formData.append('firmantes', JSON.stringify(this.dataToSend.firmantes));
      formData.append('contacto', JSON.stringify(this.dataToSend.contacto));

      // Contacto
      formData.append('contactIsNotOwner', JSON.stringify(this.dataToSend.contacto));

      // Punto de suministro
      const puntoSuministro = this.dataToSend.owner;
      puntoSuministro.address = this.dataToSend.direccion_suministro.check
        ? this.dataToSend.direccion_suministro
        : this.dataToSend.direccion_titular;
      formData.append('puntoSuministro', JSON.stringify(puntoSuministro));

      // Contacto
      formData.append(
        'differentOwnerAddress',
        JSON.stringify(this.dataToSend.direccion_suministro)
      );

      // Envio factura
      const billType = {
        send:
          this.dataToSend.envio_factura.forma_envio_factura === 'factura_papel'
            ? 'physical'
            : 'digital',
        digital:
          this.dataToSend.envio_factura.factura_electronica === 'Email del titular del suministro'
            ? this.dataToSend.owner.email
            : this.dataToSend.envio_factura.email,
        physical: {
          documentType: this.dataToSend.envio_factura.tipo_identificador,
          personType: this.dataToSend.envio_factura.tipo_persona,
          document: this.dataToSend.envio_factura.identificador,
          razonSocial: this.dataToSend.envio_factura.razon_social,
          name: this.dataToSend.envio_factura.nombre,
          lastName: this.dataToSend.envio_factura.primer_apellido,
          secondLastName: this.dataToSend.envio_factura.segundo_apellido,
          address: {
            door: this.dataToSend.envio_factura.piso,
            line1: this.dataToSend.envio_factura.direccion,
            number: this.dataToSend.envio_factura.numero,
            viaType: this.dataToSend.envio_factura.tipo_via,
            locality: this.dataToSend.envio_factura.localidad,
            province: this.dataToSend.envio_factura.provincia,
            postalCode: this.dataToSend.envio_factura.codigo_postal
          }
        }
      };

      formData.append('billType', JSON.stringify(billType));

      // Billing
      const billing = {
        iban: this.dataToSend.facturacion.iban,
        owner:
          this.dataToSend.facturacion.titularSelect === 'El titular del suministro'
            ? titularSuministro
            : {
                document: this.dataToSend.facturacion.document,
                documentType: this.dataToSend.facturacion.documentType,
                name: this.dataToSend.facturacion.name
              },
        bicSwiftCode: this.dataToSend.facturacion.swift
      };

      formData.append('billing', JSON.stringify(billing));

      const confirmations = {
        commercialComms: this.recibirComunicados,
        hiringTermsPolicyPrivacy: this.terminosCondiciones
      };

      formData.append('confirmations', JSON.stringify(confirmations));

      const notificacions = {
        nombre: this.dataToSend.envio_factura.factura_electronica_nombre,
        identificador: this.dataToSend.envio_factura.factura_electronica_identificador,
        tipo_identificador: this.dataToSend.envio_factura.factura_electronica_tipo_identificador,
        primer_apellido: this.dataToSend.envio_factura.factura_electronica_primer_apellido,
        segundo_apellido: this.dataToSend.envio_factura.factura_electronica_segundo_apellido,
        tipo_persona: this.dataToSend.envio_factura.factura_electronica_tipo_persona,
        razon_social: this.dataToSend.envio_factura.factura_electronica_razon_social,
        email: this.dataToSend.envio_factura.email
      };

      formData.append('notificacions', JSON.stringify(notificacions));

      // Documentos DNI
      if (this.arraysArchivos.documentosDNI.length > 0) {
        this.arraysArchivos.documentosDNI.forEach(fichero => {
          formData.append('documentacion_dni[]', fichero);
        });
      }

      // Documentos Documentacion Empresa
      if (this.arraysArchivos.documentacionEmpresa.length > 0) {
        this.arraysArchivos.documentacionEmpresa.forEach(fichero => {
          formData.append('documentacion_empresa[]', fichero);
        });
      }

      // Documentos Bateria Virtual
      if (this.arraysArchivos.documentacionBateriaVirtual.length > 0) {
        this.arraysArchivos.documentacionBateriaVirtual.forEach(fichero => {
          formData.append('documentacion_bateria_virtual[]', fichero);
        });
      }

      // Documentos Adicionales
      if (this.arraysArchivos.documentacionAdicional.length > 0) {
        this.arraysArchivos.documentacionAdicional.forEach(fichero => {
          formData.append('documentacion_adicional[]', fichero);
        });
      }

      // check 'Direccion suministro distinta'
      formData.append(
        'checkDireccionSuministroDistinta',
        this.dataToSend.direccion_suministro.check
      );

      // check tipo de envio
      formData.append('tipoEnvioFactura', this.dataToSend.envio_factura.forma_envio_factura);

      // email del titular u otro
      formData.append('emailTitularOtro', this.dataToSend.envio_factura.factura_electronica);

      // direccion factura
      formData.append('direccionTitularOtro', this.dataToSend.envio_factura.options_direccion);

      // titular cuenta
      formData.append('titularCuentaOtro', this.dataToSend.facturacion.titularSelect);

      this.$emit('continue', formData);
    },
    async getStaticData() {
      try {
        const response = await api.getFormInitialData();
        this.staticData.optionsCNAE = response.data.cnaes.map(cnae => {
          return { text: `${cnae.codigo} - ${cnae.descripcion}`, value: cnae.codigo };
        });
        this.staticData.optionsProvincia = response.data.provincias.map(provincia => {
          return { text: provincia.nombre, value: provincia.id };
        });
        this.staticData.optionsTipoIdentificador = response.data.user_document_types.map(
          documento => {
            return { text: `${documento.codigo} - ${documento.descripcion}`, value: documento.id };
          }
        );
        this.staticData.optionsTipoVia = response.data.tipo_vias.map(tipoVia => {
          return { text: tipoVia.descripcion, value: tipoVia.id };
        });

        this.staticData.optionsTipoContacto = response.data.tipo_contacto.map(element => {
          return { text: element.descripcion, value: element.id };
        });

        this.loaded = true;
      } catch (exception) {
        this.loaded = true;
        this.$notify.error(
          'Problema con la llamada a la api, tablas maestras, para obtener toda la informacion de los selects'
        );
      }
    },
    addFirmante() {
      this.dataToSend.firmantes.push({
        personType: 1,
        document: '',
        name: '',
        lastName: '',
        secondLastName: '',
        phone: '',
        email: '',
        confirmarEmail: '',
        razonSocial: ''
      });
    },
    addContacto() {
      this.dataToSend.contacto.push({
        documentType: 4,
        document: '',
        personType: '',
        name: '',
        lastName: '',
        secondLastName: '',
        phone: '',
        email: '',
        confirmEmail: '',
        razonSocial: ''
      });
    },
    deleteFirmante(index) {
      this.dataToSend.firmantes.splice(index, 1);
    },
    deleteContacto(index) {
      this.dataToSend.contacto.splice(index, 1);
    },
    setFactura(value) {
      // Datos del punto de medida
      this.datosPuntosSuministro.tarifa = this.checkUndefined(
        value.inputData.invoice.informacionContratacion.tarifaLuz
      );
      this.datosPuntosSuministro.comercializadora_saliente = this.checkUndefined(
        value.inputData.invoice.nombreProveedor
      );
      this.datosPuntosSuministro.p1 = this.checkUndefined(
        value.inputData.invoice.informacionContratacion.potenciaContratadaP1
      );
      this.datosPuntosSuministro.p2 = this.checkUndefined(
        value.inputData.invoice.informacionContratacion.potenciaContratadaP2
      );
      this.datosPuntosSuministro.p3 =
        this.checkUndefined(
          value.inputData.invoice.informacionContratacion.potenciaContratadaP3
        ) === ''
          ? '-'
          : value.inputData.invoice.informacionContratacion.potenciaContratadaP3;
      this.datosPuntosSuministro.p4 =
        this.checkUndefined(
          value.inputData.invoice.informacionContratacion.potenciaContratadaP4
        ) === ''
          ? '-'
          : value.inputData.invoice.informacionContratacion.potenciaContratadaP4;
      this.datosPuntosSuministro.p5 =
        this.checkUndefined(
          value.inputData.invoice.informacionContratacion.potenciaContratadaP5
        ) === ''
          ? '-'
          : value.inputData.invoice.informacionContratacion.potenciaContratadaP5;
      this.datosPuntosSuministro.p6 =
        this.checkUndefined(
          value.inputData.invoice.informacionContratacion.potenciaContratadaP6
        ) === ''
          ? '-'
          : value.inputData.invoice.informacionContratacion.potenciaContratadaP6;

      // DataToSend
      this.dataToSend.owner.document = this.checkUndefined(
        value.inputData.invoice.informacionContratacion.nif
      );

      // Direccion del suministro
      this.dataToSend.direccion_titular.postalCode = this.checkUndefined(
        value.inputData.invoice.informacionContratacion.direccionSuministro.postalCode
      );
      this.dataToSend.direccion_titular.line1 = this.checkUndefined(
        value.inputData.invoice.informacionContratacion.direccionSuministro.street
      );
      this.dataToSend.direccion_titular.number = this.checkUndefined(
        value.inputData.invoice.informacionContratacion.direccionSuministro.number
      );
      this.dataToSend.direccion_titular.floor = this.checkUndefined(
        value.inputData.invoice.informacionContratacion.direccionSuministro.letter
      );
      this.dataToSend.direccion_titular.aclarador = this.checkUndefined(
        value.inputData.invoice.informacionContratacion.direccionSuministro.standarizedAddress
      );
    },
    setData(value) {
      // Datos del punto de medida
      this.datosPuntosSuministro.tarifa = this.checkUndefined(value.externalData.tarifa);
      this.datosPuntosSuministro.comercializadora_saliente =
        this.checkUndefined(value.externalData.comercializadora) === ''
          ? '-'
          : value.externalData.comercializadora;
      this.datosPuntosSuministro.p1 =
        this.checkUndefined(value.externalData.potenciaContratada.p1) === ''
          ? '-'
          : value.externalData.potenciaContratada.p1 / 1000;

      this.datosPuntosSuministro.p2 =
        this.checkUndefined(value.externalData.potenciaContratada.p2) === ''
          ? '-'
          : value.externalData.potenciaContratada.p2 / 1000;

      this.datosPuntosSuministro.p3 =
        this.checkUndefined(value.externalData.potenciaContratada.p3) === ''
          ? '-'
          : value.externalData.potenciaContratada.p3 / 1000;

      this.datosPuntosSuministro.p4 =
        this.checkUndefined(value.externalData.potenciaContratada.p4) === ''
          ? '-'
          : value.externalData.potenciaContratada.p4 / 1000;

      this.datosPuntosSuministro.p5 =
        this.checkUndefined(value.externalData.potenciaContratada.p5) === ''
          ? '-'
          : value.externalData.potenciaContratada.p5 / 1000;

      this.datosPuntosSuministro.p6 =
        this.checkUndefined(value.externalData.potenciaContratada.p6) === ''
          ? '-'
          : value.externalData.potenciaContratada.p6 / 1000;
    },
    checkUndefined(texto) {
      if (texto !== undefined && texto !== null) return texto;
      return '';
    }
  }
};
