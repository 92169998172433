//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import './style/datos-punto-suministro-style.scss';

export default {
  name: 'DatosPuntoSuministro',
  props: {
    data: {
      type: Object,
      default: () => ({})
    },
    cups: {
      type: String,
      default: ''
    },
    datosPuntoSuministro: {
      type: Object,
      default: () => ({})
    }
  }
};
