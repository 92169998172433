//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ViuHeader from 'viu/components/viu-header/viu-header.vue';
import ViuInput from 'viu/components/viu-input/viu-input.vue';
import ViuLabel from 'viu/components/viu-label/viu-label.vue';
import ViuSelect from 'viu/components/viu-select/viu-select.vue';
import * as api from '../../../lib/api.js';
import validateForm from '$lib/validate-form.js';

import './style/direcciones-titular-style.scss';

export default {
  name: 'DireccionesTitular',
  components: {
    ViuHeader,
    ViuInput,
    ViuLabel,
    ViuSelect
  },
  props: {
    staticData: Object,
    value: Object
  },
  data() {
    return {
      optionsLocalidades: []
    };
  },
  watch: {
    'value.province': {
      handler(value) {
        if (value === '') {
          this.value.locality = '';
          return;
        }
        this.getPronvincia(value);
      }
    }
  },
  methods: {
    async getPronvincia(value) {
      if (this.checkUndefined(value) === '') return;
      const response = await api.getLocalityByProvince(value);

      this.optionsLocalidades = response.data.municipios.map(municipios => {
        return { text: municipios.nombre, value: municipios.id };
      });
    },
    checkUndefined(texto) {
      if (texto !== undefined && texto !== null) return texto;
      return '';
    },
    validate() {
      return validateForm.validate(this, []);
    }
  }
};
