//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ViuHeader from 'viu/components/viu-header/viu-header.vue';
import ViuSwitch from 'viu/components/viu-switch/viu-switch.vue';
import ViuLabel from 'viu/components/viu-label/viu-label.vue';
import ViuInput from 'viu/components/viu-input/viu-input.vue';
import ViuSelect from 'viu/components/viu-select/viu-select.vue';
import NifValidatorMixin from '$mixins/nif-validator-mixin.js';
import validateForm from '$lib/validate-form.js';
import * as api from '../../../lib/api.js';

import './style/envio-factura-style.scss';

export default {
  name: 'EnvioFactura',
  components: {
    ViuSwitch,
    ViuHeader,
    ViuLabel,
    ViuInput,
    ViuSelect
  },
  mixins: [NifValidatorMixin],
  props: {
    staticData: Object,
    value: Object
  },
  data() {
    return {
      optionsLocalidades: [],
      nifChecked: false,
      nifCheckedOtro: false
    };
  },
  computed: {
    computedErrorPattern() {
      if (
        this.value.factura_electronica_tipo_identificador === 4 &&
        this.value.factura_electronica_identificador.length > 7 &&
        this.value.factura_electronica_identificador.length < 10
      ) {
        return 'NIF incorrecto';
      }
      return 'Formato incorrecto';
    },
    computedErrorPatternOtro() {
      if (
        this.value.tipo_identificador === 4 &&
        this.value.identificador.length > 7 &&
        this.value.identificador.length < 10
      ) {
        return 'NIF incorrecto';
      }
      return 'Formato incorrecto';
    },
    computedPattern() {
      let regexp = /[\s\S]*/;
      if (
        this.value.factura_electronica_tipo_identificador === 7 ||
        this.value.factura_electronica_tipo_identificador === 6 ||
        this.value.factura_electronica_tipo_identificador === ''
      ) {
        return regexp;
      }
      if (
        this.value.factura_electronica_tipo_identificador === 4 &&
        this.value.factura_electronica_tipo_persona === 2
      ) {
        regexp = /^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/;
        return regexp;
      }
      if (
        this.value.factura_electronica_tipo_identificador === 4 &&
        this.value.factura_electronica_tipo_persona === 1
      ) {
        regexp = /^\d{8}[a-zA-Z]{1}$/;
        return regexp;
      }
      if (
        this.value.factura_electronica_tipo_identificador === 8 &&
        this.value.factura_electronica_tipo_persona === 1
      ) {
        regexp = /^[XxTtYyZz]{1}([0-9]{7}|[0-9]{8})[a-zA-Z]{1}$/;
        return regexp;
      }
      if (this.value.factura_electronica_tipo_identificador === 5) {
        regexp = /^[a-zA-Z]{2}[a-zA-Z0-9]{1,12}$/;
        return regexp;
      }
      return regexp;
    },
    computedPatternOtro() {
      let regexp = /[\s\S]*/;
      if (
        this.value.tipo_identificador === 7 ||
        this.value.tipo_identificador === 6 ||
        this.value.tipo_identificador === ''
      ) {
        return regexp;
      }
      if (this.value.tipo_identificador === 4 && this.value.tipo_persona === 2) {
        regexp = /^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/;
        return regexp;
      }
      if (this.value.tipo_identificador === 4 && this.value.tipo_persona === 1) {
        regexp = /^\d{8}[a-zA-Z]{1}$/;
        return regexp;
      }
      if (this.value.tipo_identificador === 8 && this.value.tipo_persona === 1) {
        regexp = /^[XxTtYyZz]{1}([0-9]{7}|[0-9]{8})[a-zA-Z]{1}$/;
        return regexp;
      }
      if (this.value.tipo_identificador === 5) {
        regexp = /^[a-zA-Z]{2}[a-zA-Z0-9]{1,12}$/;
        return regexp;
      }
      return regexp;
    }
  },
  watch: {
    'value.provincia': {
      handler(value) {
        if (value === '') {
          this.value.localidad = '';
          return;
        }
        this.getPronvincia(value);
      }
    },
    'value.factura_electronica_identificador': {
      handler(valor) {
        if (/[a-z]/.test(valor)) {
          this.value.factura_electronica_identificador = this.value.factura_electronica_identificador.toUpperCase();
        }
        if (
          this.value.factura_electronica_tipo_identificador === 4 &&
          this.value.factura_electronica_identificador === ''
        ) {
          this.value.factura_electronica_tipo_persona = 1;
        }
        if (
          this.value.factura_electronica_tipo_identificador === 8 ||
          (this.value.factura_electronica_tipo_identificador === 4 &&
            /[0-9]/.test(this.value.factura_electronica_identificador[0])) ||
          this.value.factura_electronica_tipo_identificador === 7
        ) {
          this.value.factura_electronica_tipo_persona = 1;
        }

        if (
          /[a-zA-Z]/.test(this.value.factura_electronica_identificador[0]) &&
          this.value.factura_electronica_tipo_identificador === 4 &&
          this.value.factura_electronica_identificador !== ''
        ) {
          this.value.factura_electronica_tipo_persona = 2;
        }

        if (
          this.value.factura_electronica_tipo_identificador === 5 ||
          this.value.factura_electronica_tipo_identificador === 6
        ) {
          this.value.factura_electronica_tipo_persona = 2;
        }

        this.checkNIFIdentifier();
      }
    },
    'value.factura_electronica_tipo_identificador': {
      handler() {
        if (
          this.value.factura_electronica_tipo_identificador === 4 &&
          this.value.factura_electronica_identificador === ''
        ) {
          this.value.factura_electronica_tipo_persona = 1;
        }
        if (
          this.value.factura_electronica_tipo_identificador === 8 ||
          (this.value.factura_electronica_tipo_identificador === 4 &&
            /[0-9]/.test(this.value.factura_electronica_identificador[0])) ||
          this.value.factura_electronica_tipo_identificador === 7
        ) {
          this.value.factura_electronica_tipo_persona = 1;
        }
        if (
          this.value.factura_electronica_tipo_identificador === 4 &&
          /[a-zA-Z]/.test(this.value.factura_electronica_identificador[0]) &&
          this.value.factura_electronica_identificador !== ''
        ) {
          this.value.factura_electronica_tipo_persona = 2;
        }
        if (
          this.value.factura_electronica_tipo_identificador === 5 ||
          this.value.factura_electronica_tipo_identificador === 6
        ) {
          this.value.factura_electronica_tipo_persona = 2;
        }

        this.checkNIFIdentifier();
      }
    },
    'value.identificador': {
      handler(valor) {
        if (/[a-z]/.test(valor)) {
          this.value.identificador = this.value.identificador.toUpperCase();
        }
        if (this.value.tipo_identificador === 4 && this.value.identificador === '') {
          this.value.tipo_persona = 1;
        }
        if (
          this.value.tipo_identificador === 8 ||
          (this.value.tipo_identificador === 4 && /[0-9]/.test(this.value.identificador[0])) ||
          this.value.tipo_identificador === 7
        ) {
          this.value.tipo_persona = 1;
        }

        if (
          /[a-zA-Z]/.test(this.value.identificador[0]) &&
          this.value.tipo_identificador === 4 &&
          this.value.identificador !== ''
        ) {
          this.value.tipo_persona = 2;
        }

        if (this.value.tipo_identificador === 5 || this.value.tipo_identificador === 6) {
          this.value.tipo_persona = 2;
        }

        this.checkNIFIdentifier();
      }
    },
    'value.tipo_identificador': {
      handler() {
        if (this.value.tipo_identificador === 4 && this.value.identificador === '') {
          this.value.tipo_persona = 1;
        }
        if (
          this.value.tipo_identificador === 8 ||
          (this.value.tipo_identificador === 4 && /[0-9]/.test(this.value.identificador[0])) ||
          this.value.tipo_identificador === 7
        ) {
          this.value.tipo_persona = 1;
        }
        if (
          this.value.tipo_identificador === 4 &&
          /[a-zA-Z]/.test(this.value.identificador[0]) &&
          this.value.identificador !== ''
        ) {
          this.value.tipo_persona = 2;
        }
        if (this.value.tipo_identificador === 5 || this.value.tipo_identificador === 6) {
          this.value.tipo_persona = 2;
        }

        this.checkNIFIdentifier();
      }
    }
  },
  methods: {
    async getPronvincia(value) {
      if (this.checkUndefined(value) === '') return;
      const response = await api.getLocalityByProvince(value);

      this.optionsLocalidades = response.data.municipios.map(municipios => {
        return { text: municipios.nombre, value: municipios.id };
      });
    },
    checkUndefined(texto) {
      if (texto !== undefined && texto !== null) return texto;
      return '';
    },
    validate() {
      return validateForm.validate(this, []);
    },
    checkNIFIdentifier() {
      this.nifChecked = false;

      if (
        this.value.factura_electronica_tipo_identificador === 4 &&
        (this.value.factura_electronica_identificador.length === 8 ||
          this.value.factura_electronica_identificador.length === 9) &&
        (/^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/.test(this.value.factura_electronica_identificador) ||
          /^\d{8}[a-zA-Z]{1}$/.test(this.value.factura_electronica_identificador))
      ) {
        const validNIF = this.NIFValidator(this.value.factura_electronica_identificador);

        if (!validNIF) {
          this.nifChecked = true;
        }
      }
    },
    checkNIFIdentifierOtro() {
      this.nifChecked = false;

      if (
        this.value.tipo_identificador === 4 &&
        (this.value.identificador.length === 8 || this.value.identificador.length === 9) &&
        (/^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/.test(this.value.identificador) ||
          /^\d{8}[a-zA-Z]{1}$/.test(this.value.identificador))
      ) {
        const validNIF = this.NIFValidator(this.value.identificador);

        if (!validNIF) {
          this.nifChecked = true;
        }
      }
    }
  }
};
