//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ViuHeader from 'viu/components/viu-header/viu-header.vue';
import ViuInput from 'viu/components/viu-input/viu-input.vue';
import ViuLabel from 'viu/components/viu-label/viu-label.vue';
import ViuSelect from 'viu/components/viu-select/viu-select.vue';
import NifValidatorMixin from '$mixins/nif-validator-mixin.js';
import { getBicSwiftByIban } from '../../../lib/api.js';
import validateForm from '$lib/validate-form.js';

import './style/facturacion-style.scss';

export default {
  name: 'Facturacion',
  components: {
    ViuHeader,
    ViuInput,
    ViuLabel,
    ViuSelect
  },
  mixins: [NifValidatorMixin],
  props: {
    staticData: Object,
    value: Object
  },
  data() {
    return {
      nifChecked: false
    };
  },
  computed: {
    computedErrorPattern() {
      if (
        this.value.documentType === 4 &&
        this.value.document.length > 7 &&
        this.value.document.length < 10
      ) {
        return 'NIF incorrecto';
      }
      return 'Formato incorrecto';
    },
    computedPattern() {
      let regexp = /[\s\S]*/;
      if (
        this.value.documentType === 7 ||
        this.value.documentType === 6 ||
        this.value.documentType === ''
      ) {
        return regexp;
      }
      if (this.value.documentType === 4 && this.value.personType === 2) {
        regexp = /^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/;
        return regexp;
      }
      if (this.value.documentType === 4 && this.value.personType === 1) {
        regexp = /^\d{8}[a-zA-Z]{1}$/;
        return regexp;
      }
      if (this.value.documentType === 8 && this.value.personType === 1) {
        regexp = /^[XxTtYyZz]{1}([0-9]{7}|[0-9]{8})[a-zA-Z]{1}$/;
        return regexp;
      }
      if (this.value.documentType === 5) {
        regexp = /^[a-zA-Z]{2}[a-zA-Z0-9]{1,12}$/;
        return regexp;
      }
      return regexp;
    }
  },
  watch: {
    'value.iban': {
      deep: true,
      handler() {
        this.getSwift();
      }
    },
    'value.document': {
      handler(valor) {
        if (/[a-z]/.test(valor)) {
          this.value.document = this.value.document.toUpperCase();
        }
        if (this.value.documentType === 4 && this.value.document === '') {
          this.value.personType = 1;
        }
        if (
          this.value.documentType === 8 ||
          (this.value.documentType === 4 && /[0-9]/.test(this.value.document[0])) ||
          this.value.documentType === 7
        ) {
          this.value.personType = 1;
        }

        if (
          /[a-zA-Z]/.test(this.value.document[0]) &&
          this.value.documentType === 4 &&
          this.value.document !== ''
        ) {
          this.value.personType = 2;
        }

        if (this.value.documentType === 5 || this.value.documentType === 6) {
          this.value.personType = 2;
        }

        this.checkNIFIdentifier();
      }
    },
    'value.documentType': {
      handler() {
        if (this.value.documentType === 4 && this.value.document === '') {
          this.value.personType = 1;
        }
        if (
          this.value.documentType === 8 ||
          (this.value.documentType === 4 && /[0-9]/.test(this.value.document[0])) ||
          this.value.documentType === 7
        ) {
          this.value.personType = 1;
        }
        if (
          this.value.documentType === 4 &&
          /[a-zA-Z]/.test(this.value.document[0]) &&
          this.value.document !== ''
        ) {
          this.value.personType = 2;
        }
        if (this.value.documentType === 5 || this.value.documentType === 6) {
          this.value.personType = 2;
        }

        this.checkNIFIdentifier();
      }
    }
  },
  methods: {
    async getSwift() {
      try {
        const ibanLength = this.value.iban.length;
        const rightLength = ibanLength >= 16 && ibanLength <= 34; // Longitud basada en la excepción lanzada por la librería https://github.com/xafardero/generate-iban/blob/master/src/Iban.php

        if (rightLength) {
          const response = await getBicSwiftByIban(this.value.iban);
          const { bicSwift } = response.data;
          this.value.swift = bicSwift;
        }
      } catch (error) {
        // console.log(error);
      }
    },
    validate() {
      return validateForm.validate(this, []);
    },
    checkNIFIdentifier() {
      this.nifChecked = false;

      if (
        this.value.documentType === 4 &&
        (this.value.document.length === 8 || this.value.document.length === 9) &&
        (/^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/.test(this.value.document) ||
          /^\d{8}[a-zA-Z]{1}$/.test(this.value.document))
      ) {
        const validNIF = this.NIFValidator(this.value.document);

        if (!validNIF) {
          this.nifChecked = true;
        }
      }
    }
  }
};
