//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ViuHeader from 'viu/components/viu-header/viu-header.vue';
import ViuButton from 'viu/components/viu-button/viu-button.vue';
import ViuUpload from 'viu/components/viu-upload/viu-upload.vue';
import validateForm from '$lib/validate-form.js';
import * as api from '$network/api.js';

import './style/cumplimentacion-rapida-style.scss';

export default {
  name: 'CumplimentacionRapida',
  components: { ViuHeader, ViuUpload, ViuButton },
  props: {
    leadId: {
      type: String
    }
  },
  data() {
    return {
      invalid: false,
      sending: false,
      documentos: []
    };
  },
  methods: {
    procesarFactura() {
      if (this.sending) return;

      const result = validateForm.validate(this, []);

      if (result !== 0) return;
      this.sending = true;

      const datosAdjunto = new FormData();

      datosAdjunto.append('file', this.documentos[0]);

      datosAdjunto.append('action', 'contract');

      api
        .procesarFactura(this.leadId, datosAdjunto)
        .then(response => {
          if ('invoice' in response.data.steps[2].inputData)
            this.$emit('factura-procesada', response.data.steps[2]);
          this.sending = false;
        })
        .catch(() => {
          this.sending = false;
          this.$notify.error('Ha ocurrido un error al procesar su factura.');
        });
    }
  }
};
