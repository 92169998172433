//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ViuHeader from 'viu/components/viu-header/viu-header.vue';
import ViuButton from 'viu/components/viu-button/viu-button.vue';
import NifValidatorMixin from '$mixins/nif-validator-mixin.js';
import validateForm from '$lib/validate-form.js';

import Contacto from './contacto.vue';

import './style/contacto-style.scss';

export default {
  name: 'Contactos',
  components: {
    ViuHeader,
    ViuButton,
    Contacto
  },
  mixins: [NifValidatorMixin],
  props: {
    staticData: Object,
    value: Array
  },
  data() {
    return {
      optionsTypeContacto: [],
      nifChecked: false
    };
  },

  watch: {
    'staticData.optionsTipoContacto': {
      deep: true,
      handler() {
        this.optionsTypeContacto = this.staticData.optionsTipoContacto.filter(
          element => element.text !== 'Firmante'
        );
      }
    }
  },
  methods: {
    addContacto() {
      this.$emit('addContacto');
    },
    deleteContacto(index) {
      this.$emit('deleteContacto', index);
    },
    validate() {
      const result = this.value.map((element, ind) => {
        return validateForm.validate(this, [], ind);
      });
      return result.reduce((acc, datos) => acc + datos, 0);
    }
  }
};
