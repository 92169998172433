//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ViuHeader from 'viu/components/viu-header/viu-header.vue';
import ViuLabel from 'viu/components/viu-label/viu-label.vue';
import ViuSelect from 'viu/components/viu-select/viu-select.vue';
import ViuInput from 'viu/components/viu-input/viu-input.vue';
import NifValidatorMixin from '$mixins/nif-validator-mixin.js';
import validateForm from '$lib/validate-form.js';
import * as api from '$network/api.js';

import './style/titulares-contacto-style.scss';

export default {
  name: 'TitularesSuministro',
  components: { ViuHeader, ViuLabel, ViuSelect, ViuInput },
  mixins: [NifValidatorMixin],
  props: {
    value: Object,
    staticData: Object,
    leadId: String,
    allSteps: Object
  },
  data() {
    return {
      nifChecked: false,
      buttonMessage: '',
      buttonState: ''
    };
  },
  computed: {
    computedErrorPattern() {
      if (
        this.value.documentType === 4 &&
        this.value.document.length > 7 &&
        this.value.document.length < 10
      ) {
        return 'NIF incorrecto';
      }
      return 'Formato incorrecto';
    },
    computedPattern() {
      let regexp = /[\s\S]*/;
      if (
        this.value.documentType === 7 ||
        this.value.documentType === 6 ||
        this.value.documentType === ''
      ) {
        return regexp;
      }
      if (this.value.documentType === 4 && this.value.personType === 2) {
        regexp = /^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/;
        return regexp;
      }
      if (this.value.documentType === 4 && this.value.personType === 1) {
        regexp = /^\d{8}[a-zA-Z]{1}$/;
        return regexp;
      }
      if (this.value.documentType === 8 && this.value.personType === 1) {
        regexp = /^[XxTtYyZz]{1}([0-9]{7}|[0-9]{8})[a-zA-Z]{1}$/;
        return regexp;
      }
      if (this.value.documentType === 5) {
        regexp = /^[a-zA-Z]{2}[a-zA-Z0-9]{1,12}$/;
        return regexp;
      }
      return regexp;
    }
  },
  watch: {
    'value.document': {
      handler(valor) {
        if (/[a-z]/.test(valor)) {
          this.value.document = this.value.document.toUpperCase();
        }
        if (this.value.documentType === 4 && this.value.document === '') {
          this.value.personType = 1;
        }
        if (
          this.value.documentType === 8 ||
          (this.value.documentType === 4 && /[0-9]/.test(this.value.document[0])) ||
          this.value.documentType === 7
        ) {
          this.value.personType = 1;
        }

        if (
          /[a-zA-Z]/.test(this.value.document[0]) &&
          this.value.documentType === 4 &&
          this.value.document !== ''
        ) {
          this.value.personType = 2;
        }

        if (this.value.documentType === 5 || this.value.documentType === 6) {
          this.value.personType = 2;
        }

        this.checkNIFIdentifier();
        this.getScoring(this.value.document);
      }
    },
    'value.documentType': {
      handler() {
        if (this.value.documentType === 4 && this.value.document === '') {
          this.value.personType = 1;
        }
        if (
          this.value.documentType === 8 ||
          (this.value.documentType === 4 && /[0-9]/.test(this.value.document[0])) ||
          this.value.documentType === 7
        ) {
          this.value.personType = 1;
        }
        if (
          this.value.documentType === 4 &&
          /[a-zA-Z]/.test(this.value.document[0]) &&
          this.value.document !== ''
        ) {
          this.value.personType = 2;
        }
        if (this.value.documentType === 5 || this.value.documentType === 6) {
          this.value.personType = 2;
        }

        this.checkNIFIdentifier();
        this.getScoring(this.value.document);
      }
    }
  },
  methods: {
    checkNIFIdentifier() {
      this.nifChecked = false;

      if (
        this.value.documentType === 4 &&
        (this.value.document.length === 8 || this.value.document.length === 9) &&
        (/^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/.test(this.value.document) ||
          /^\d{8}[a-zA-Z]{1}$/.test(this.value.document))
      ) {
        const validNIF = this.NIFValidator(this.value.document);

        if (!validNIF) {
          this.nifChecked = true;
        }
      }
    },
    validate() {
      return validateForm.validate(this, []);
    },
    async getScoring(document) {
      if (
        this.checkUndefined(document) === '' ||
        this.allSteps[1].inputData.house_type !== 'business'
      )
        return;
      if (this.NIFValidator(document) && this.value.documentType === 4) {
        await api
          .checkScoring(this.leadId, document)
          .then(response => {
            if (response.status === 200) {
              switch (response.data.status_scoring) {
                case 'desactivado':
                case 'aprobado':
                  this.buttonMessage = 'Scoring aprobado';
                  this.buttonState = 'aprobado';
                  this.$emit('scoring', false);
                  break;
                case 'manual':
                  this.buttonMessage = 'Scoring Pendiente Aprobación Manual';
                  this.buttonState = 'manual';
                  this.$emit('scoring', false);
                  break;
                default:
                  this.buttonMessage = 'Scoring negativo';
                  this.buttonState = 'denegado';
                  this.$emit('scoring', true);
                  break;
              }
            }
          })
          .catch(() => {
            this.$notify.error('Error al comprobar el scoring, consulte con el administrador.');
          });
      }
    },
    checkUndefined(texto) {
      if (texto !== undefined && texto !== null) return texto;
      return '';
    }
  }
};
