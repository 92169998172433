//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ViuUpload from 'viu/components/viu-upload/viu-upload.vue';
import ViuHeader from 'viu/components/viu-header/viu-header.vue';
import ViuSelect from 'viu/components/viu-select/viu-select.vue';

import './style/upload-files-style.scss';

export default {
  name: 'UploadFiles',
  components: {
    ViuHeader,
    ViuUpload,
    ViuSelect
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    subtitle: {
      type: String,
      default: ''
    },
    upload: {
      type: Boolean,
      default: false
    },
    selectFiles: {
      type: Boolean,
      default: false
    },
    staticData: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      arrayDocumentos: [],
      dataToSend: {
        fichero: []
      }
    };
  },
  watch: {
    // value: {
    //   immediate: true,
    //   deep: true,
    //   handler(valor) {
    //     if (valor.length === 0) return;
    //     this.arrayDocumentos = valor.map((documentos, index) => {
    //       return {
    //         nombre: this.checkUndefined(documentos[index].name),
    //         size: this.checkUndefined(documentos[index].size)
    //       };
    //     });
    //   }
    // },
    dataToSend: {
      deep: true,
      handler(valor) {
        this.$emit('update', valor.fichero);
      }
    }
  },
  methods: {
    checkUndefined(texto) {
      if (texto !== undefined && texto !== null) return texto;
      return '';
    },
    eliminar(index) {
      this.value.splice(index, 1);
      this.$emit('input', this.value);
    }
  }
};
